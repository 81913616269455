import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Container, Title, Row } from "../../../ui-kit"
import Img from "gatsby-image"

// styles
import s from "./WhyUs.module.sass"
// stroke numbers
import One from "../../../icons/why-us/one.svg"
import Two from "../../../icons/why-us/two.svg"
import Three from "../../../icons/why-us/three.svg"
import { LayoutContext } from "../../Layouts/layout"

export default function WhyUs({list, icons}) {
    const { local: { YAMLContent } } = useContext(LayoutContext)

    return (
        <section className={s.whyUs}>
            <Container size="fluid" padding="no-padding">
                <Title className={s.title}>{YAMLContent.whyUs}</Title>
                <Row align="align-items-start">
                    <div className={s.imgWrapper}>
                        <div className={s.imgInner}>
                            <Img fluid={list.image.localFile ? list.image.localFile?.childImageSharp.fluid : ""} className="height100" />
                        </div>
                    </div>
                    <div className={s.content}>
                        <Item Count={One}>{list.advantage_1 || list.advantage_1_ua}</Item>
                        <Item Count={Two}>{list.advantage_2 || list.advantage_2_ua}</Item>
                        <Item Count={Three}>{list.advantage_3 || list.advantage_3_ua}</Item>
                    </div>
                </Row>
            </Container>
            <Container>
                <Row align="align-items-start">
                    {
                        icons.map(({node}) => {
                            return <Advantage icon={node.icon.localFile.publicURL} key={node.id}>{node.description || node.description_ua}</Advantage>
                        })
                    }
                </Row>
            </Container>
        </section>
    )
}

const Item = ({Count, children}) => {
    return (
        <div className={s.item}>
            <p className={s.count}><Count/></p>
            <p className={s.text}>{children}</p>
        </div>
    )
}

const Advantage = ({icon, children}) => {
    return (
        <div className={s.advantage}>
            <p className={s.icon}><img src={icon} alt="icon" /></p>
            <h4 className={s.atitle}>{children}</h4>
        </div>
    )
}

Item.propTypes = {
    children: PropTypes.node,
    Count: PropTypes.func,
}

Advantage.propTypes = {
    children: PropTypes.node,
    Icon: PropTypes.func,
}