import React, { useState, useEffect, useContext, useRef } from "react"
import { Link } from "gatsby"
import classNames from "classnames"
// import PropTypes from "prop-types"
// styles
import s from "./SolarPowerPlants.module.sass"
// ui-kit
import { 
    Title, 
    Row,
    Button,
    Container
} from "../../../ui-kit"
// icons
import Orbit from "../../../icons/solar-power-plants/orbit.svg"
import Circles1 from "../../../icons/solar-power-plants/circles-green.svg"
import Circles2 from "../../../icons/solar-power-plants/circles-gray.svg"
import { LayoutContext } from "../../Layouts/layout"

export default function SolarPowerPlants({content}) {    
    const { local: { YAMLContent } } = useContext(LayoutContext)
    const [circle, setCircle] = useState(0);
    const sect = useRef();
    
    useEffect(() => {
        let htmlEl = document.documentElement;
        let wTop = 0;
        let timeout = 0;
        let scrollEnd = false;
        let index = 0;

        function scrollSolar() {
            if (!scrollEnd && sect.current && window.scrollY > sect.current.offsetTop && (window.scrollY + window.innerHeight) < (sect.current.offsetTop + sect.current.clientHeight)) {
                wTop = window.scrollY;
                htmlEl.classList.add(s.fixed);
                htmlEl.style.top = -wTop+"px";

                htmlEl.onwheel = (e) => {
                    if (!scrollEnd) {
                        clearTimeout(timeout);
                        timeout = setTimeout(() => {
                            return index++;
                        }, 66)
                        
                        if (index > 2) {
                            setCircle(0);
                            scrollEnd = true;
                            htmlEl.classList.remove(s.fixed);
                            htmlEl.style.top = 0+"px";
                            window.scrollTo(0, wTop);
                        } else {
                            setCircle(index);
                        }
                    }
                };
            }
        }
        
        if (document.documentElement.clientWidth >= 1366) {
            window.addEventListener("wheel", scrollSolar)
        } else {
            window.removeEventListener("wheel", scrollSolar)
        }

        return () => {
            window.removeEventListener("wheel", scrollSolar)
            index = 0;
            wTop = 0;
            htmlEl.classList.remove(s.fixed);
            htmlEl.removeAttribute("style");
        }
    }, []);
    
    function circleClassName(index) {
        if (index === 0) {
            return classNames({
                [s.current]: circle === 0,
                [s.prev]: circle === 2,
                [s.next]: circle === 1,
            })
        }
        if (index === 1) {
            return classNames({
                [s.current]: circle === 1,
                [s.prev]: circle === 0,
                [s.next]: circle === 2,

            })
        }
        if (index === 2) {
            return classNames({
                [s.current]: circle === 2,
                [s.prev]: circle === 1,
                [s.next]: circle === 0,
            })
        }
    }
    
    return (
        <section className={classNames(s.solarPowerPlants, s.desktop)} ref={sect}>
            <Container size="fluid">
                <Title>{YAMLContent.price}</Title>
                <div className={s.wrapper}>
                    {
                        content.map(({id, currency = '$', price, name, list, link}, index) => {
                            return <Circle 
                                key={id}
                                currency={currency}
                                price={price}
                                name={name}
                                list={list}
                                link={link}
                                className={circleClassName(index)}
                                onClick={() => setCircle(index)}
                            />
                        })
                    }
                    <div className={classNames(s.orbit, {
                        [s.deg15]: circle === 0,
                        [s.deg30]: circle === 1,
                        [s.deg45]: circle === 2,
                    })}><Orbit/></div>
                    <div className={s.circles1}><Circles1/></div>
                    <div className={s.circles2}><Circles2/></div>
                    <div className={s.circles3}><Circles2/></div>
                    <div className={s.circles4}><Circles2/></div>
                    <div className={s.circles5}><Circles1/></div>
                    <div className={s.circles6}><Circles1/></div>
                    <div className={s.circles7}><Circles2/></div>
                </div>
            </Container>
        </section>
    )
}

const Circle = ({ currency, price, name, list, className, link, onClick}) => {
    const { local: { YAMLGlobal } } = useContext(LayoutContext)

    return (
        <div className={classNames(s.circle, className)} onClick={onClick}>
            <div className={s.content}>
                <p className={s.price}>{currency}{price}</p>
                <p className={s.power}>кВт</p>
                <h3 className={s.name}>{name}</h3>
                <div className={s.list}>
                    {
                        list.map(({title, type}, index) => (
                            <p className={s.item} key={index}>
                                <i className={classNames(s.icon, {
                                    [s.plus]: type.includes('+'),
                                    [s.minus]: type.includes('-')
                                })}></i>
                                {title}
                            </p>
                        ))
                    }
                </div>
                <Row justify="justify-content-center">
                    <Link to={link}><Button className={classNames("green", s.button)}>{YAMLGlobal.button.more}</Button></Link>
                </Row>
            </div>
        </div>
    )
}