import React, { useContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import classNames from "classnames"
import Img from "gatsby-image"
// ui-kit
import { Title, Container, Slider } from '../../../ui-kit'
// style
import s from './OurClients.module.sass'
// svg
import Play from "../../../icons/our-clients/play.svg"
import { LayoutContext } from "../../Layouts/layout"
export default function OurClients({clients}) {
    const { local: { YAMLContent } } = useContext(LayoutContext)
    const data = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "pattern_green_clients.png" }) {
                childImageSharp {
                    fluid(maxWidth: 548) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    
    return(
        <section className={s.ourClients}>
            <Container>
                <Title>{YAMLContent.clients}</Title>
                <div className={s.wrapper}>
                    <div className={s.rectLeft}></div>
                    <div className={s.rectRight}></div>
                    <div className={s.pattern}><Img fluid={data.placeholderImage.childImageSharp.fluid}/></div>
                    <Slider 
                        element={s.clients} 
                        options={{
                            type: "carousel",
                            startAt: 0,
                            gap: 0,
                            perView: 3,
                            focusAt: "center",
                            arrows: true,
                            breakpoints: {
                                992: {
                                    perView: 2.5
                                },
                                768: {
                                    perView: 1.5,
                                    bullets: true,
                                    arrows: false
                                },
                                620: {
                                    perView: 1,
                                    gap: 30
                                }
                            }
                        }}
                        activeClass={s.active}>
                        {
                            clients.map(({node: {id, ...node}}) => {
                                return <SliderItem key={id} node={node}/>
                            })
                        }
                    </Slider>
                </div>
            </Container>
        </section>
    )
}

const SliderItem = ({node}) => {
    const { local: { langBase } } = useContext(LayoutContext)

    return (
        <li className={s.sliderItem}>
            <div 
                className={classNames(s.slide, s.video)}
                data-src={`${langBase}/${node.slug}`}>
                <div className={s.img}>
                    <Img className="height100 width100" fluid={node.image.localFile ? node.image.localFile?.childImageSharp.fluid : ""}/>
                    <span className={s.play}><Play/></span>
                </div>
                <h4 className={s.name}>{node.name}</h4>
                <p className={s.project}>{node.project}</p>
            </div>
        </li>
    )
}