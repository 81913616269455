import React, { useContext } from "react"
import GlideSlider from "./GlideSlider"
import classNames from "classnames"
import { Link } from "gatsby"
import Img from "gatsby-image"

// Components
import {
  Button,
  Container,
  Title
} from "../../../ui-kit"
// Styles
import s from "./FirstScreen.module.sass"
// context
import { LayoutContext } from "../../Layouts/layout"

const FirstScreen = ({slides}) => {

  const { local: { YAMLContent } } = useContext(LayoutContext)
  
  return (
    <section className={s.main}>
      <Container size="fluid" padding="no-padding">
        <GlideSlider options={
          {
            type: "carousel",
            gap: 0,
            startAt: 0,
            perView: 1
          }
        }>
          {
            slides.map(({node}) => {
              return <SliderItem 
                key={node.id}
                src={node.image.localFile ? node.image.localFile?.childImageSharp.fluid : null}
                subtitle={node.subtitle || node.subtitle_ua || YAMLContent.innovation}
                title={node.title || node.title_ua}
                button_link={node.button_link || node.button_link_ua}
                button_text={node.button_text || node.button_text_ua}
              />
            })
          }
        </GlideSlider>
      </Container>
    </section>
  )
}

const SliderItem = ({src, subtitle, title, button_link, button_text}) => {
  const { local: { YAMLGlobal } } = useContext(LayoutContext)

  return(
    <li className={s.sliderItem}>
      <div className={s.img}>
        { src ? <Img fluid={src} className="height100" /> : null}
      </div>
      <div className={s.content}>
        <Title type="subtitle" className="white">{subtitle}</Title>
        <Title className={s.title} type="page">{title}</Title>
        <Link to={button_link}><Button className={classNames("green", s.btn)}>{button_text || YAMLGlobal.button.more}</Button></Link>
      </div>
    </li>
  )
}

export default FirstScreen