import React, { useState, useEffect, useContext } from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"
import classNames from "classnames"
// ui-kit
import { Container, Title, Button, Row } from "../../../ui-kit"
// svg
import Wave from "../../../icons/completed-projects/wave.svg"
// styles
import s from "./CompletedProjects.module.sass"
import { LayoutContext } from "../../Layouts/layout"
export default function CompletedProjects({projects}) {
    const { local: { langBase, YAMLContent, YAMLGlobal } } = useContext(LayoutContext)

    const [wave, setWave] = useState(null);
    
    useEffect(() => {
        if (document.documentElement.clientWidth <= 768) {
            setWave(null);
        } else {
            setWave(<Wave/>)
        }

        window.addEventListener('resize', function() {
            if (document.documentElement.clientWidth <= 768) {
                setWave(null);
            } else {
                setWave(<Wave/>)
            }
        })
        
    }, [])
    
    return (
        <section className={s.completedProjects}>
            { wave }
            <Container>
                <Title className={s.title}>{YAMLContent.projects}</Title>
                <div className={s.wrapper}>
                    {
                        projects.map(({ node }) => {
                            return <Project key={node.id} node={node} />
                        })
                    }
                </div>
                <Row justify="justify-content-center">
                    <Link to={`${langBase}/projects/category/setevye-elektrostancii`}>
                        <Button className={classNames("green", s.button)}>{YAMLGlobal.button.projects}</Button>
                    </Link>
                </Row>
            </Container>
        </section>

    )
}

const Project = ({node}) => {
    const { local: { YAMLGlobal } } = useContext(LayoutContext)

    return (
        <div className={s.project}>
            <Link to={`/projects/${node.slug}/`} className={s.projectLink}>
                <div className={s.link}>{YAMLGlobal.button.more}</div>
                <div className={s.img}>
                    <div className={s.inner}>
                        <Img className="height100 width100" fluid={node.title_photo.localFile ? node.title_photo.localFile?.childImageSharp.fluid : ""}/>
                    </div>
                </div>
                <h4 className={s.name}>{node.title || node.title_ua}</h4>
            </Link>
        </div>
    )
}