import React, { useEffect, useState } from 'react'
import Glide from "@glidejs/glide"
import "@glidejs/glide/dist/css/glide.core.min.css"
import "@glidejs/glide/dist/css/glide.theme.min.css"

import classNames from "classnames"
import s from "./GlideSlider.module.sass"

const GlideSlider = ({element = 'glide', options, children}) => {
    const [slider] = useState(new Glide(`.${element}`, options))
    const [active, setActive] = useState(options.startAt);
    
    useEffect(() => {
        slider.on("run.after", function(e) {
            setActive(slider.index);
        });
        slider.mount();
        return () => slider.destroy()
    }, [])

    return (
        <div className={classNames(element, s.slider)}>
            <div className={classNames("glide__track", s.track)} data-glide-el="track">
                <ul className="glide__slides">
                    {children.map((slide, index) => {
                            return React.cloneElement(slide, {
                                key: index,
                                className: `${slide.props.className} glide__slide`
                            })
                        })
                    }
                </ul>
            </div>
            <div className={s.bullets} data-glide-el="controls[nav]">
                {
                    children.map((slide, index) => {
                        return (
                            <span 
                                data-glide-dir={`=${index}`}
                                className={classNames(s.bullet, {[s.active]: index === active})} 
                                key={index}
                                onClick={() => slider.go(`=${index}`)}>
                                {index+1}
                            </span>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default GlideSlider;