import React, { useContext } from "react"
import { graphql, useStaticQuery } from 'gatsby'
import classNames from "classnames"
import Img from "gatsby-image"
// components
import { 
    Container, 
    Title, 
    Button, 
    Row, 
    Slider
} from "../../../ui-kit"
// styles
import s from "./StagesOfWork.module.sass"
// svg
import Circle1 from "../../../icons/stages-of-work/circle-1.svg"
import Circle2 from "../../../icons/stages-of-work/circle-2.svg"
import Stage2 from "../../../icons/stages-of-work/stage-2.svg"
import Stage3 from "../../../icons/stages-of-work/stage-3.svg"
import Wave from "../../../icons/stages-of-work/wave-white.svg"
// context
import { LayoutContext } from "../../Layouts/layout"
// img icon
import img from "../../../images/list-icon.png";

export default function StagesOfWorkSlider({stages}) {
    const { local: { YAMLGlobal } } = useContext(LayoutContext)
    const data = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "pattern_green.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 217) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `)
    
    return (
        <section className={s.stagesOfWork}>
            <Wave/>
            <Container>
                <Title className={s.title}>{YAMLGlobal.steps}</Title>
                <Slider
                    element="stages"
                    overflow="visible"
                    options={{
                        startAt: 0,
                        perView: 1,
                        bullets: true,
                        gap: 60,
                    }}
                >
                    {
                        stages.map(({node}, index) => {
                            return <li className={s.stagesSlide} key={node.id}>
                            <div className={s.stages}>
                                <div className={classNames(s.stage, {
                                    [s.stage1]: (index+1)%5 === 1,
                                    [s.stage2]: (index+1)%5 === 2,
                                    [s.stage3]: (index+1)%5 === 3,
                                    [s.stage4]: (index+1)%5 === 4,
                                    [s.stage5]: (index+1)%5 === 0
                                })}>
                                    <div className={s.content}>
                                        <div className={classNames({
                                            [s.sharp1]: (index+1)%5 === 1,
                                            [s.sharp2]: (index+1)%5 === 2,
                                            [s.sharp4]: (index+1)%5 === 4,
                                            [s.sharp5]: (index+1)%5 === 0
                                        })}>
                                            { // надо было массивом делать
                                                (index+1)%5 === 1 ? <Img fluid={data.placeholderImage.childImageSharp.fluid}/> : (
                                                    (index+1)%5 === 2 ? <Stage2/> : null
                                                )
                                            }
                                        </div>
                                        <div className={s.count}>{index+1 < 10 ? `0${index+1}` : (index+1) }</div>
                                        <h4 className={s.name}>{node.title || node.title_ua}</h4>
                                        {
                                            node.list ? <div className={s.contentInner} dangerouslySetInnerHTML={
                                                {__html: node.list
                                                    .replace(/<p[^>]*>(.*?)<\/p>/gs, `<p class="${s.p}">$1</p>`)
                                                    .replace(/<li[^>]*>(.*?)<\/li>/gs, `<li class="${s.li}"><div class="${s.icon}"><img src="${img}" width="22" ></div>$1</li>`)
                                                }
                                            }></div> :
                                            <div className={s.contentInner} dangerouslySetInnerHTML={
                                                {__html: node.list_ua
                                                    .replace(/<p[^>]*>(.*?)<\/p>/gs, `<p class="${s.p}">$1</p>`)
                                                    .replace(/<li[^>]*>(.*?)<\/li>/gs, `<li class="${s.li}"><div class="${s.icon}"><img src="${img}" width="22" ></div>$1</li>`)
                                                }
                                            }></div>
                                        }
                                    </div>
                                </div>
                                <div className={classNames(s.img, {
                                    [s.img1]: (index+1)%5 === 1,
                                    [s.img2]: (index+1)%5 === 2,
                                    [s.img3]: (index+1)%5 === 3,
                                    [s.img4]: (index+1)%5 === 4,
                                    [s.img5]: (index+1)%5 === 0
                                })}>
                                    <Img className={s.gatsbyImg} fluid={node.image.localFile ? node.image.localFile?.childImageSharp.fluid : ""} />
                                </div>
                                {
                                    (index+1)%5 === 2 ? <div className={s.circleSlider2}><Circle2/></div> : (
                                        (index+1)%5 === 3 ? <div className={s.circleSlider1}><Circle1/></div> : (
                                            (index+1)%5 === 0 ? 
                                                <>
                                                    <div className={s.sharp5}><Stage2/></div>
                                                    <div className={s.sharp51}><Stage3/></div>
                                                </> : null
                                        )
                                    )
                                }
                            </div>
                        </li>
                        })
                    }
                    
                </Slider>
                <Row justify="justify-content-center">
                    <Button
                        className={classNames("green", s.button)}
                        onClick={()=>{}}>
                        {YAMLGlobal.button.orderProject}
                    </Button>
                </Row>
            </Container>
        </section>
    )
}