import React, { useState, useEffect, useContext } from "react"
import { Link } from "gatsby"
import classNames from "classnames"
// import PropTypes from "prop-types"
// styles
import s from "./SolarPowerPlants.module.sass"
// ui-kit
import { 
    Title, 
    Row,
    Button,
    Container,
    Slider
} from "../../../ui-kit"
// icons
import Circles from "../../../icons/circles-group/rounds-green.svg"
import { LayoutContext } from "../../Layouts/layout"

export default function SolarPowerPlantsSmall({content}) {    
    const { local: { YAMLContent } } = useContext(LayoutContext)
    
    return (
        <section className={classNames(s.solarPowerPlants, s.small)}>
            <Container size="fluid">
                <Title>{YAMLContent.price}</Title>
                <div className={s.wrapperMob}>
                    <div className={s.circlesSvg}><Circles/></div>
                    <Slider
                        element="solarMob"
                        options={{
                            startAt: 0,
                            perView: 2,
                            gap: 32,
                            bullets: true,
                            breakpoints: {
                                768: {
                                    perView: 1
                                }
                            }
                        }}
                    >
                        {
                            content.map(({id, currency = '$', price, name, list, link}) => {
                                return <li key={id} className={s.sliderItem}><Circle 
                                    currency={currency}
                                    price={price}
                                    name={name}
                                    list={list}
                                    link={link}
                                    className={null}
                                /></li>
                            })
                        }
                    </Slider>
                </div>
            </Container>
        </section>
    )
}

const Circle = ({ currency, price, name, list, className, link}) => {
    const { local: { YAMLGlobal } } = useContext(LayoutContext)

    return (
        <div className={classNames(s.circleMob, className)}>
            <div className={s.content}>
                <div>
                    <p className={s.price}>{currency}{price}</p>
                    <p className={s.power}>кВт</p>
                </div>
                <h3 className={s.name}>{name}</h3>
                {/* <div className={s.list}> */}
                    {
                        list.map(({title, type}, index) => (
                            <p className={s.item} key={index}>
                                <i className={classNames(s.icon, {
                                    [s.plus]: type.includes('+'),
                                    [s.minus]: type.includes('-')
                                })}></i>
                                {title}
                            </p>
                        ))
                    }
                {/* </div> */}
                <Row justify="justify-content-center">
                    <Button className={classNames("green", s.button)}><Link to={link}>{YAMLGlobal.button.more}</Link></Button>
                </Row>
            </div>
        </div>
    )
}